'use client'

import {useLocale, useTranslations} from "next-intl";
import {englishConfig, localeConfigs} from "@/i18n.tsx";
import {LocaleSelectorButton} from "@/components/i18n/LocaleSelectorButton.tsx";
import style from "./FooterLocaleSwitch.module.sass";
import SvgIcon from "@/components/icons/SvgIcon.tsx";

export function FooterLocaleSwitch() {
  const t = useTranslations()
  const locale = useLocale()
  const currentLocale = localeConfigs.find(l => l.code === locale) || englishConfig
  return <div className={style['container']}>
    <div className={style['innerContainer']}>
      <h3 className={style['title']}>{t('footer.language')}</h3>
      <LocaleSelectorButton className={style['languageSelect']} openClassName={style['open']}>
        {currentLocale.name}
        <SvgIcon href={"/icons/icons.svg#chevronFat"} side={24} box={20} className={style['languageIcon']}/>
      </LocaleSelectorButton>
    </div>
  </div>
}