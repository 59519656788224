'use client'

import {ReactNode, useEffect, useRef, useState} from "react";
import style from "./LocaleSelectorButton.module.sass";
import {useLocale} from "next-intl";
import {usePathname} from "@/navigation.ts";
import {classList} from "@/utils/lib.ts";
import {localeConfigs} from "@/i18n.tsx";

export function LocaleSelectorPanel({open}: { open: boolean }) {
  const locale = useLocale()
  const pathname = usePathname()
  return <ul className={classList(style['languageDropdown'], !open && style['hidden'])} aria-hidden={open ? "false" : "true"}>
    {localeConfigs.map(l => <li key={l.code}>
      <a href={l.prefix + pathname} className={l.code === locale ? style['active'] : ''} tabIndex={open ? undefined : -1}>
        {l.name}
      </a>
    </li>)}
  </ul>
}

export function LocaleSelectorButton({className, openClassName, children}: {
  className?: string,
  openClassName?: string,
  children: ReactNode
}) {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleOutsideClick = (event: MouseEvent) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }
  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [buttonRef])
  return <div className={style['selectorContainer']}>
    <button className={[className, open && openClassName].join(' ')}
            onClick={() => setOpen(!open)}
            ref={buttonRef}>
      {children}
    </button>
    <div className={style['panelContainer']}>
      <LocaleSelectorPanel open={open}/>
    </div>
  </div>
}
