'use client'

import style from "./HeaderLanguageSwitch.module.sass";
import {useLocale} from "next-intl";
import {englishConfig, localeConfigs} from "@/i18n.tsx";
import {LocaleSelectorButton} from "@/components/i18n/LocaleSelectorButton.tsx";
import SvgIcon from "@/components/icons/SvgIcon.tsx";

export function HeaderLanguageSwitch() {
  const locale = useLocale()
  const currentLocale = localeConfigs.find(l => l.code === locale) || englishConfig
  return <LocaleSelectorButton className={style['languageSelect']} openClassName={style['open']}>
    {currentLocale.name}
    <SvgIcon href={"/icons/icons.svg#chevronFat"} side={24} box={20} className={style['languageIcon']}/>
  </LocaleSelectorButton>
}
