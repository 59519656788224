export default function SvgIcon({href, side, box, className}: {
  href: string
  side: number
  box?: number
  className?: string
}) {
  return <svg width={side} height={side} viewBox={`0 0 ${box || side} ${box || side}`} className={className}>
    <use href={href}/>
  </svg>
}
