import {createSharedPathnamesNavigation} from "next-intl/navigation";
import {getLocale, locale, localeConfigsMap, locales} from "@/i18n";
import {UrlObject} from "node:url";
import {redirect as nextRedirect} from "next/navigation";
import {RedirectType} from "next/dist/client/components/redirect";

export const NEXT_PUBLIC_ROOT_URL = process.env.NEXT_PUBLIC_ROOT_URL || "http://localhost:3000";

const nextIntlNavigation = createSharedPathnamesNavigation({
  locales: locales,
  localePrefix: 'as-needed',
});

export const usePathname = nextIntlNavigation.usePathname

export async function redirect(pathname: string, type?: RedirectType) {
  const locale = await getLocale()
  return nextRedirect(locale.prefix + pathname, type)
}

export function absoluteUrl(locale: locale, path: string | UrlObject): string {
  return `${NEXT_PUBLIC_ROOT_URL}${localeConfigsMap[locale].prefix}${path.toString()}`
}
